import React, { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import { FaChevronRight, FaEdit, FaPhoneAlt, FaUserAlt } from 'react-icons/fa';
import BackButton from '../../components/BackButton';
import Loader from '../../components/SplashScreen/loader';
import { getDischargeHistory } from '../../redux/archived-patients';
import { reset } from '../../redux/archived-patients/slice';
import useDebounce from '../../utils/useDebounceHook';
import { getFormattedDateTime } from '../../utils/helper';

const DischargeHistory = () => {
	const [filterText, setFilterText] = useState('');
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [columnSort, setColumnSort] = useState({});
	const dispatch = useDispatch();
	const { id } = useParams();
	const { currentHospital } = useSelector(state => state.auth);
	const { dischargeHistoryListError, dischargeHistoryList, Loading } = useSelector(state => state.archivedPatient);
	const debouncedSearchTerm = useDebounce(filterText, 500);
	const subHeaderComponentMemo = useMemo(() => {
		return (
			<Form.Group as={Row} controlId="formGridSearchText">
				<Form.Control
					type="text"
					placeholder={t('GENERAL.SEARCHTEXT')}
					name="search_text"
					value={filterText}
					onChange={(e) => {
						setFilterText(e.target.value);
					}} />
			</Form.Group>
		);
	}, [filterText]);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(dischargeHistoryList)) {
			setTotalRows(dischargeHistoryList?.discharge_history?.total);
			setPerPage(dischargeHistoryList?.discharge_history?.per_page);
		}
	}, [dischargeHistoryList]);

	useEffect(() => {
		if (!isEmpty(currentHospital) && debouncedSearchTerm == '') {
			dispatch(getDischargeHistory({ page: 1, per_page: perPage, p_id: id, hospital_id: currentHospital.value, ...columnSort }));
		}
		else if (!isEmpty(currentHospital) && debouncedSearchTerm !== '') {
			dispatch(getDischargeHistory({ page: 1, per_page: perPage, p_id: id, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
		}
	}, [currentHospital, debouncedSearchTerm, columnSort]);

	useEffect(() => {
		if (!isEmpty()) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	}, [dischargeHistoryListError]);

	const handlePageChange = page => {
		dispatch(getDischargeHistory({ page: page, per_page: perPage, p_id: id, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
	};
	const handlePerRowsChange = async(newPerPage, page) => {
		dispatch(getDischargeHistory({ page: page, per_page: newPerPage, p_id: id, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
	};

	const columns = [
		{
			name: t('DISCHARGEHISTORY.COLUMNS.DOA'),
			selector: row => getFormattedDateTime(row.admitted_at),
			sortable: true,
			sortField: 'admitted_at',
		},
		{
			name: t('DISCHARGEHISTORY.COLUMNS.DOD'),
			selector: row => getFormattedDateTime(row.discharged_at),
			sortable: true,
			sortField: 'discharged_at',
		},
		{
			name: t('DISCHARGEHISTORY.COLUMNS.IPNO'),
			cell: row => (
				<Button title={t('DISCHARGEHISTORY.COLUMNS.IPNO')} size="sm" as={Link} to={`/archived-forms/${id}/${row.ip_no}`}>
					<span>{row.ip_no} <FaChevronRight style={{ marginBottom: '2px' }} /> </span>
				</Button>
			),
			sortable: true,
			sortField: 'ip_no',
		},
		{
			name: '',
			cell: row => row?.is_cancel ?
				(<span className="text-danger">{t('PATIENTS.CANCELLED_ADMISSION')}</span>) : null,
			ignoreRowClick: true,
			sortable: false,
		}
	];

	const handleSort = async(column, sortDirection) => {
		setColumnSort({ column_name: column.sortField, order: sortDirection });
	};
	return (
		<Container className='mt-4'>
			{dischargeHistoryListError &&
				<Alert variant='danger'>
					<ul className='mb-0'>
						{dischargeHistoryListError && dischargeHistoryListError.split('|').map((error, index) => (
							<li key={index}>{error}</li>
						))}
					</ul>
				</Alert>}
			<Row className='my-3'>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>{t('DISCHARGEHISTORY.TITLE')}</h5>
					<BackButton />
				</Col>
			</Row>
			<hr />
			{!isEmpty(dischargeHistoryList) && <Row className='mt-4'>
				<Col>
					<Alert variant='success' className='px-3 py-1'>
						<span style={{ fontSize: '13px' }}>
							<FaUserAlt style={{ marginBottom: '2px', marginRight: '5px' }} /> {dischargeHistoryList?.patient_info?.name},
							<FaPhoneAlt style={{ marginBottom: '3px', marginLeft: '5px', marginRight: '5px' }} /> {dischargeHistoryList?.patient_info?.mobile},
							<FaEdit style={{ marginBottom: '3px', marginLeft: '5px', marginRight: '5px' }} /> {t('DISCHARGEHISTORY.REGISTRATIONNO')} ({dischargeHistoryList?.patient_info?.reg_no})
						</span>
					</Alert>
				</Col>
			</Row>}
			<Row>
				<Col>
					<DataTable
						columns={columns}
						data={dischargeHistoryList?.discharge_history?.data}
						keyField='ip_no'
						progressPending={Loading}
						progressComponent={<Loader />}
						highlightOnHover
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						subHeader
						subHeaderComponent={subHeaderComponentMemo}
						onSort={handleSort}
						sortServer
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default DischargeHistory;
