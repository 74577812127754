import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { combineReducers } from 'redux';
import authReducer from './auth/slice';
import userReducer from './users/slice';
import patientReducer from './patients/slice';
import formReducer from './forms/slice';
import hospitalReducer from './hospital/slice';
import dischargeClinicalReducer from './discharge-clinical/slice';
import archivedPatientReducer from './archived-patients/slice';
import monitoringReducer from './monitoring/slice';
import hospitalMessagesReducer from './chat/slice';

const hospitalPersistConfig = {
	key: 'hospital',
	storage,
	whitelist: ['currentPatient', 'currentPatientIpno', 'hospitalBedInfo']
};

const authPersistConfig = {
	key: 'auth',
	storage,
	whitelist: ['currentHospital']
};

const reducers = combineReducers({
	auth: persistReducer(authPersistConfig, authReducer),
	users: userReducer,
	patients: patientReducer,
	forms: formReducer,
	hospital: persistReducer(hospitalPersistConfig, hospitalReducer),
	dischargeClinical: dischargeClinicalReducer,
	archivedPatient: archivedPatientReducer,
	monitoring: monitoringReducer,
	hospitalMessages: hospitalMessagesReducer,
});
const rootReducer = (state, action) => reducers(state, action);

export const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
export const persistor = persistStore(store);