import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { bool } from 'prop-types';
import { Alert, Button, Col, Container, Modal, Row, Stack } from 'react-bootstrap';
import { isEmpty, map, join, isString, compact } from 'lodash';
import { ImCancelCircle } from 'react-icons/im';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../SplashScreen/loader';
import DynamicForm from '.';
import useQuery from '../../utils/useQuery';
import { getSubFormFields, addSubFormFields, getEditSubFormFields, updateSubFormFields, deleteSubFormRecord } from '../../redux/forms';
import { useLocation } from 'react-router-dom';

function SubFormModal(props) {
	const [initialValues, setInitialValues] = useState({});
	const dispatch = useDispatch();
	const { onHide, field, show, parentformuniqueid, isPatientForm, isClinicalDischargeForm, action } = props;
	const { patientId } = useQuery();
	const { currentHospital } = useSelector(state => state.auth);
	const { currentPatient } = useSelector(state => state.hospital);
	const { currentPatientDetail } = useSelector(state => state.dischargeClinical);
	const { subFormDetail, subFormDetailError, subFormAddResponse, subFormAddError, subFormUpdateResponse, subFormUpdateError, subFormDeleteMessage, subFormDeleteError, Loading } = useSelector(state => state.forms);
	const location = useLocation();
	const isArchived = location.pathname.startsWith('/archived_forms');

	useEffect(() => {
		if (!isEmpty(currentHospital) && !isEmpty(field) && (show === true)) {
			if (field?.id && action == 'edit') {
				dispatch(getEditSubFormFields({ form_id: field.form_id, hospital_id: currentHospital.value, form_unique_id: field.form_unique_id, 'sub_form_unique_id': field.sub_form_unique_id, 'field_name': field.field_name, 'id': field.id, p_id: currentPatient.p_id }));
			}
			else if (action == 'add') {
				dispatch(getSubFormFields({ form_id: field.formdata_id, hospital_id: currentHospital.value, field_name: field.field_name, form_unique_id: parentformuniqueid, p_id: currentPatient.p_id }));
			}
		}
	}, [currentHospital, field, show]);

	/* useEffect(() => {
		return () => {
			dispatch(resetSubForm());
		};
	}, []); */

	useEffect(() => {
		if (parentformuniqueid && !isEmpty(subFormDetail) && !isEmpty(subFormDetail?.form_fields)) {
			let fields = {};
			subFormDetail?.form_fields.map((field) => {
				if (action == 'add') {
					if (field.type != 'checkbox') {
						fields[field.field_name] = field?.default_values || '';
					}
					else {
						fields[field.field_name] = field?.default_values?.split(',') || [];
					}
				}
				else if (action == 'edit') {
					if (field.type != 'checkbox') {
						fields[field.field_name] = field?.value || '';
					}
					else {
						fields[field.field_name] = field?.value?.split(',') || [];
					}
				}
			});
			setInitialValues(fields);
		}
		else {
			setInitialValues({});
		}
	}, [subFormDetail]);

	useEffect(() => {
		if (!isEmpty(subFormAddResponse) || !isEmpty(subFormUpdateResponse) || !isEmpty(subFormDeleteMessage) ) {
			onHide();
		}
	}, [subFormAddResponse, subFormUpdateResponse, subFormDeleteMessage]);

	const handleDeleteSubForm = () => {
		let formdata = new FormData();
		formdata.append('form_id', field.form_id);
		formdata.append('hospital_id', currentHospital.value);
		formdata.append('form_unique_id', parentformuniqueid);
		formdata.append('sub_form_unique_id', field.sub_form_unique_id);
		formdata.append('field_name', field.field_name);
		formdata.append('id', field.id);
		dispatch(deleteSubFormRecord(formdata));
	};
	const handleAddSubFormSubmit = (values) => {
		let formdata = new FormData();
		if (subFormDetail?.form_id > 0) {
			formdata.append('form_id', subFormDetail.form_id);
			formdata.append('id', subFormDetail.id);
		}
		else {
			formdata.append('form_id', subFormDetail.id);
		}
		formdata.append('hospital_id', currentHospital.value);
		formdata.append('field_name', field.field_name);
		formdata.append('form_unique_id', parentformuniqueid);
		formdata.append('sub_form_unique_id', field.sub_form_unique_id);
		formdata.append('ip_no', currentPatient.ip_no);
		formdata.append('isArchived', isArchived);
		if (isPatientForm) {
			formdata.append('patient_form', 'yes');
		}
		else {
			if (isClinicalDischargeForm) {
				formdata.append('clinical_discharge_form', 'yes');
			}
			formdata.append('p_id', patientId || currentPatientDetail?.patient_info?.panel_title?.id || currentPatient?.p_id);
		}
		Object.entries(values).map((field) => {
			formdata.append('formdata[' + field[0] + ']', field[1]);
			subFormDetail?.form_fields.map((formfield) => {
				if (formfield.field_name === field[0] && formfield.type === 'select') {
					if (!isString(field[1])) {
						formdata.delete('formdata[' + field[0] + ']');
						if (formfield.possible_values != '*') {
							formdata.append('formdata[' + field[0] + ']', field[1]?.value || '');
						}
						else {
							formdata.append('formdata[' + field[0] + ']', join(map(field[1], 'value'), ','));
						}
					}
				}
				else if (formfield.field_name === field[0] && formfield.type === 'checkbox') {
					formdata.delete('formdata[' + field[0] + ']');
					formdata.append('formdata[' + field[0] + ']', join(compact(field[1]), ','));
				}
				/* else if (formfield.field_name === field[0] && formfield.type === 'text' && formfield.autocomplete == '1') {
					formdata.delete('formdata[' + field[0] + ']');
					formdata.append('formdata[' + field[0] + ']', field[1]?.value || '');
				} */
			});
		});
		if (subFormDetail?.form_id > 0) {
			dispatch(updateSubFormFields(formdata));
		}
		else {
			dispatch(addSubFormFields(formdata));
		}
	};
	return (
		<Modal {...props} size={action != 'delete' ? 'lg' : 'md'} aria-labelledby="subform-modal" centered={action == 'delete'}>
			{action != 'delete' && <Modal.Header closeButton>
				<Modal.Title id="subform-modal">
					{subFormDetail?.name}
					{action == 'delete' && t('GENERAL.DELETETITLE')}
				</Modal.Title>
			</Modal.Header>}
			<Modal.Body>
				{(subFormDetailError || subFormAddError || subFormUpdateError || subFormDeleteError) &&
					<Alert variant='danger'>
						<ul className='mb-0'>
							{subFormDetailError && subFormDetailError.split('|').map((error, index) => (
								<li key={index}>{error}</li>
							))}
							{subFormAddError && subFormAddError.split('|').map((error, index) => (
								<li key={index}>{error}</li>
							))}
							{subFormUpdateError && subFormUpdateError.split('|').map((error, index) => (
								<li key={index}>{error}</li>
							))}
							{subFormDeleteError && subFormDeleteError.split('|').map((error, index) => (
								<li key={index}>{error}</li>
							))}
						</ul>
					</Alert>}
				{Loading && action != 'delete' &&
					<Row className='d-flex justify-content-center mt-4'>
						<Loader />
					</Row>}
				{action == 'delete' &&
					<>
						<Row>
							<Col className='text-center text-danger'>
								<span style={{ fontSize: '60px' }}>
									<ImCancelCircle />
								</span>
							</Col>
						</Row>
						<Row>
							<Col className='text-center text-muted'>
								<h3>
									Are You Sure ?
								</h3>
							</Col>
						</Row>
						<Row className='my-3 justify-content-center'>
							<Col className='text-center text-muted'>
								<Container>
									<span>{t('GENERAL.CONFIRMDELETE')}</span>
								</Container>
							</Col>
						</Row>
						<Row className='my-3'>
							<Stack className='justify-content-center' direction='horizontal' gap={4}>
								<Button title={t('GENERAL.CANCEL')} size='md' className='px-4' variant="secondary" onClick={onHide}>{t('GENERAL.CANCEL')}</Button>
								<Button title={t('GENERAL.DELETE')} size='md' className='px-4' variant="danger" onClick={handleDeleteSubForm} disabled={Loading}>
									{t('GENERAL.DELETE')}
								</Button>
							</Stack>
						</Row>
					</>
				}
				{!isEmpty(initialValues) && action != 'delete' &&
					<DynamicForm ReInitialize={true} initialValues={initialValues} fields={subFormDetail.form_fields} handleCancel={onHide} handleSubmit={handleAddSubFormSubmit} loading={Loading} />
				}
			</Modal.Body>
		</Modal >
	);
}
SubFormModal.propTypes = {
	isPatientForm: bool,
	isClinicalDischargeForm : bool
};
SubFormModal.defaultProps = {
	isPatientForm: false,
	isClinicalDischargeForm: false
};
export default SubFormModal;