import React from 'react';
import { t } from 'i18next';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import { isEmpty, find, includes, isEqual } from 'lodash';
import moment, { now } from 'moment';

export const AuthContext = React.createContext(null);

export const useAuth = () => React.useContext(AuthContext);

export const generateYupSchema = (fields) => {
	let validationObject = {};
	let hiddArr = [];
	fields?.map((field) => {
		let rule = field.field_rule;
		let fieldrule = rule && rule.split('|');
		if (fieldrule && includes(fieldrule, 'med_freq_type') && fieldrule.length > 2) {
			let hfields = fieldrule[1].split(',');
			hiddArr = [...hiddArr, ...hfields];
		}
	});
	fields?.map((field) => {
		let rule = field.field_rule;
		let fieldrule = rule && rule.split('|');
		let schema = {};
		if (field.required && !includes(hiddArr, field.field_name)) {
			if (field.type == 'text') {
				schema = yup.string();
			}
			else if (field.type == 'number') {
				schema = yup.number();
			}
			else {
				schema = yup.mixed();
			}
			validationObject[field.field_name] = schema.concat(schema.required(field.title + t('ERRORS.REQUIREDFIELD')).label(field.title));
		}
		if (includes(fieldrule, 'email')) {
			schema = yup.string().email();
			validationObject[field.field_name] = schema.concat(schema.label(field.title));
		}
	});
	return yup.object().shape(validationObject);
};

export const getDateFormat = () => {
	return 'DD-MM-YYYY';
};

export const getDateTimeFormat = () => {
	return 'DD-MM-YYYY hh:mm A';
};

export const getFormattedDate = (date) => {
	return moment(date).format(getDateFormat());
};

export const getFormattedDateTime = (dateTime) => {
	return moment(dateTime).format(getDateTimeFormat());
};

export const getFormattedDateTimeByUnixTimeStamp = (seconds) => {
	return moment.unix(seconds).format(getDateTimeFormat());
};

export const getBetweenDates = (startDate, endDate) => {
	let dates = [];
	let currDate = moment(startDate).startOf('day');
	let lastDate = moment(endDate).startOf('day');
	dates.push(currDate.format(getDateFormat()));
	while (currDate.subtract(1, 'days').diff(lastDate) > 0) {
		dates.push(currDate.clone().format(getDateFormat()));
	}
	if (isEqual(currDate.format(getDateFormat()), lastDate.format(getDateFormat()))) {
		dates.push(lastDate.format(getDateFormat()));
	}
	return dates;
};

export const slugify = (str) => {
	return str
		.toLowerCase()
		.trim()
		.replace(/[^a-z0-9]/gi, '');
};

export const getDefaultFormID = (formKey) => {
	const forms = [
		{ formId: 17, formKey: 'Investigation & Treatment Orders - MO' },
		{ formId: 20, formKey: 'Upload Form Documents' },
		{ formId: 22, formKey: 'Investigation Sheet' },
		{ formId: 34, formKey: 'Drug Administration Orders(Nurse)' },
		{ formId: 35, formKey: 'Discharge Summary' },
		{ formId: 39, formKey: 'Handover Form MO' },
		{ formId: 40, formKey: 'Handover Form Nurse' },
		{ formId: 41, formKey: 'Dischage Checklist' },
	];
	let form = find(forms, (o) => { return o.formKey === formKey; });
	if (form) {
		return form.formId;
	}
	return 0;
};

export const getHelpVideoDetail = (videoKey) => {
	const videos = [
		{ videoKey: 'add_patient', videoTitle: 'How to add a Patient | Axia', videoURL: 'https://www.youtube.com/embed/5NYVHuXXeGk' },
		{ videoKey: 'manage_bed_labels_types', videoTitle: 'Managing Bed Type & Labels | Axia', videoURL: 'https://www.youtube.com/embed/M0xa_jRLZBE' },
		{ videoKey: 'shift_patient', videoTitle: 'Shifting patient between Ward & ICU | Axia', videoURL: 'https://www.youtube.com/embed/skjE08BuKKA' },
	];
	const video = videos.find((o) => o.videoKey === videoKey);
	return video || 0;
};

export const getAge = (dateString) => {
	let today = new Date();
	let birthDate = new Date(dateString);
	let age = today.getFullYear() - birthDate.getFullYear();
	let m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	return age;
};

export const getMedicine = (medicine) => {
	let medicineArr = medicine.split('|');
	return medicineArr;
};

export const checkRoleAccess = (roles, currentHospital) => {
	let roleAccess = false;
	roles.map(role => {
		if (roleAccess) {
			return roleAccess;
		}
		else if (role == 'owner') {
			roleAccess = (currentHospital.is_admin == 'yes');
		}
		else if (role == 'mo') {
			roleAccess = (currentHospital.role == 'doctor' && currentHospital.is_admin == 'no');
		}
		else if (role == 'co-ordinator') {
			roleAccess = (currentHospital.role == 'co-ordinator');
		}
		else if (role == 'nurse') {
			roleAccess = (currentHospital.role == 'nurse');
		}
		else if (role == 'physiotherapist') {
			roleAccess = (currentHospital.role == 'physiotherapist');
		}
		else if (role == 'dietitian') {
			roleAccess = (currentHospital.role == 'dietitian');
		}
		else if (role == 'consultant') {
			roleAccess = (currentHospital.role == 'consultant');
		}
		else if (role == 'admin') {
			roleAccess = (currentHospital.role == 'admin');
		}
	});
	return roleAccess;
};

export const checkMenuAccess = (menuKey, dischargedAt) => {
	const { currentHospital } = useSelector(state => state.auth);
	const { currentPatient } = useSelector(state => state.hospital);

	if (menuKey === 'dashboard') {
		return checkRoleAccess(['owner', 'mo', 'co-ordinator', 'nurse', 'physiotherapist', 'dietitian', 'consultant', 'admin'], currentHospital);
	}
	else if (menuKey === 'users') {
		return checkRoleAccess(['owner', 'admin'], currentHospital);
	}
	else if (menuKey === 'forms') {
		return checkRoleAccess(['owner', 'admin'], currentHospital);
	}
	else if (menuKey === 'patients') {
		return checkRoleAccess(['owner', 'mo', 'co-ordinator', 'nurse', 'physiotherapist', 'dietitian', 'admin'], currentHospital);
	}
	else if (menuKey === 'discharge_admin') {
		return checkRoleAccess(['owner', 'co-ordinator', 'admin', 'mo'], currentHospital);
	}
	else if (menuKey === 'discharge_clinical') {
		return checkRoleAccess(['owner', 'mo', 'admin', 'consultant'], currentHospital);
	}
	else if (menuKey === 'patient_monitoring') {
		return !isEmpty(currentPatient) && !dischargedAt && checkRoleAccess(['owner', 'mo', 'nurse', 'physiotherapist', 'dietitian', 'consultant', 'admin'], currentHospital);
	}
	else if (menuKey === 'clinical_forms') {
		return !isEmpty(currentPatient) && !dischargedAt && checkRoleAccess(['owner', 'admin'], currentHospital);
	}
	else if (menuKey === 'nurse_clinical_forms') {
		return !isEmpty(currentPatient) && !dischargedAt && checkRoleAccess(['nurse'], currentHospital);
	}
	else if (menuKey === 'mo_clinical_forms') {
		return !isEmpty(currentPatient) && !dischargedAt && checkRoleAccess(['mo'], currentHospital);
	}
	else if (menuKey === 'consultant_clinical_forms') {
		return !isEmpty(currentPatient) && !dischargedAt && checkRoleAccess(['consultant'], currentHospital);
	}
	else if (menuKey === 'physiotherapist_clinical_forms') {
		return !isEmpty(currentPatient) && !dischargedAt && checkRoleAccess(['physiotherapist'], currentHospital);
	}
	else if (menuKey === 'dietitian_clinical_forms') {
		return !isEmpty(currentPatient) && !dischargedAt && checkRoleAccess(['dietitian'], currentHospital);
	}
	else if (menuKey === 'archived_patients') {
		return checkRoleAccess(['owner', 'mo', 'admin'], currentHospital);
	}
	else if (menuKey === 'settings') {
		return checkRoleAccess(['owner', 'mo', 'co-ordinator', 'nurse', 'physiotherapist', 'dietitian', 'consultant', 'admin'], currentHospital);
	}
	else if (menuKey === 'videocall') {
		return checkRoleAccess(['owner', 'mo', 'co-ordinator', 'nurse', 'physiotherapist', 'dietitian', 'consultant', 'admin'], currentHospital);
	}
	return false;
};

export const GetClinicalSubMenus = () => {
	const { currentHospital } = useSelector(state => state.auth);
	if (currentHospital.role == 'doctor' || currentHospital.role == 'admin') {
		return <>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/doctor'}>
				<span className='text-capitalize'>{t('GENERAL.MO')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/nurse'}>
				<span className='text-capitalize'>{t('GENERAL.NURSE')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/consultant'}>
				<span className='text-capitalize'>{t('GENERAL.CONSULTANT')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/physiotherapist'}>
				<span className='text-capitalize'>{t('GENERAL.PHYSIOTHERAPIST')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/dietitian'}>
				<span className='text-capitalize'>{t('GENERAL.DIETITIAN')}</span>
			</NavDropdown.Item>
		</>;
	}
	else if (currentHospital.is_admin == 'no' && currentHospital.role == 'nurse') {
		return <>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/nurse'}>
				<span className='text-capitalize'>{t('GENERAL.NURSE')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/doctor'}>
				<span className='text-capitalize'>{t('GENERAL.MO')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/consultant'}>
				<span className='text-capitalize'>{t('GENERAL.CONSULTANT')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/physiotherapist'}>
				<span className='text-capitalize'>{t('GENERAL.PHYSIOTHERAPIST')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/dietitian'}>
				<span className='text-capitalize'>{t('GENERAL.DIETITIAN')}</span>
			</NavDropdown.Item>
		</>;
	}
	else if (currentHospital.is_admin == 'no' && currentHospital.role == 'consultant') {
		return <>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/consultant'}>
				<span className='text-capitalize'>{t('GENERAL.CONSULTANT')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/doctor'}>
				<span className='text-capitalize'>{t('GENERAL.MO')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/nurse'}>
				<span className='text-capitalize'>{t('GENERAL.NURSE')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/physiotherapist'}>
				<span className='text-capitalize'>{t('GENERAL.PHYSIOTHERAPIST')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/dietitian'}>
				<span className='text-capitalize'>{t('GENERAL.DIETITIAN')}</span>
			</NavDropdown.Item>
		</>;
	}
	else if (currentHospital.is_admin == 'no' && currentHospital.role == 'physiotherapist') {
		return <>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/physiotherapist'}>
				<span className='text-capitalize'>{t('GENERAL.PHYSIOTHERAPIST')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/doctor'}>
				<span className='text-capitalize'>{t('GENERAL.MO')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/nurse'}>
				<span className='text-capitalize'>{t('GENERAL.NURSE')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/consultant'}>
				<span className='text-capitalize'>{t('GENERAL.CONSULTANT')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/dietitian'}>
				<span className='text-capitalize'>{t('GENERAL.DIETITIAN')}</span>
			</NavDropdown.Item>
		</>;
	}
	else if (currentHospital.is_admin == 'no' && currentHospital.role == 'dietitian') {
		return <>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/dietitian'}>
				<span className='text-capitalize'>{t('GENERAL.DIETITIAN')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/doctor'}>
				<span className='text-capitalize'>{t('GENERAL.MO')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/nurse'}>
				<span className='text-capitalize'>{t('GENERAL.NURSE')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/consultant'}>
				<span className='text-capitalize'>{t('GENERAL.CONSULTANT')}</span>
			</NavDropdown.Item>
			<NavDropdown.Item as={Link} to={'/clinical_forms/role/physiotherapist'}>
				<span className='text-capitalize'>{t('GENERAL.PHYSIOTHERAPIST')}</span>
			</NavDropdown.Item>
		</>;
	}
};

export const downloadPDFFile = (data) => {
	const blob = new Blob([data], { type: 'application/pdf' });
	const url = URL.createObjectURL(blob);

	const a = document.createElement('a');
	a.download = now() + '.pdf';
	a.href = url;
	a.target = '_self';
	a.click();

	setTimeout(() => {
		a.remove();
		URL.revokeObjectURL(url);
	}, 100);
};