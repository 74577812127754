import React, { useEffect, useState } from 'react';
import { Alert, Card, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { isEmpty, map, join, isString, compact } from 'lodash';
import { useParams, useLocation } from 'react-router-dom';
import DynamicForm from '../../components/DynamicForm';
import PatientInfo from '../../components/Patient/patient-info';
import BackButton from '../../components/BackButton';
import Loader from '../../components/SplashScreen/loader';
import { reset } from '../../redux/forms/slice';
import { getPatientInfo } from '../../redux/hospital';
import { getFormHistoryEdit, updateForm } from '../../redux/forms';
import { changeSubFormList } from '../../redux/forms/slice';
import { getFormattedDateTime } from '../../utils/helper';

const EditClinicalForm = () => {
	const [initialValues, setInitialValues] = useState({});
	const { currentPatient, currentPatientDetail } = useSelector(state => state.hospital);
	const { formHistoryEditResponse, formHistoryEditError, updateFormResponse, updateFormError, Loading } = useSelector(state => state.forms);
	const { currentHospital } = useSelector(state => state.auth);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { formId, id } = useParams();
	const { search, pathname } = useLocation();
	const query = new URLSearchParams(search);
	const action = query.get('action');
	const isArchived = pathname.startsWith('/archived_forms');

	useEffect(() => {
		if (isEmpty(currentPatient)) {
			navigate('/');
		}
		else {
			if (isEmpty(currentPatientDetail) || (currentPatient.p_id !== currentPatientDetail?.patient_info?.panel_title?.id)) {
				dispatch(getPatientInfo({ hospital_id: currentHospital.value, p_id: currentPatient.p_id }));
			}
		}
		if (!isEmpty(currentHospital)) {
			dispatch(getFormHistoryEdit({ hospital_id: currentHospital.value, form_id: formId, id, p_id: currentPatient.p_id, bed_type : currentPatient.bed_type, isArchived }));
		}
	}, [currentPatient, currentPatientDetail, currentHospital]);

	useEffect(() => {
		if (!isEmpty(formHistoryEditResponse?.form_fields)) {
			let fields = {};
			formHistoryEditResponse?.form_fields.map((field) => {
				if (field.type != 'checkbox') {
					fields[field.field_name] = field?.value || '';
				}
				else {
					fields[field.field_name] = field?.value?.split(',') || [];
				}
			});
			setInitialValues(fields);
		}
	}, [formHistoryEditResponse]);

	useEffect(() => {
		if (!isEmpty(updateFormResponse)) {
			navigate(-1);
		}
	}, [updateFormResponse]);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(formHistoryEditError)) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	}, [formHistoryEditError]);

	const goBack = () => {
		action == 'add'? navigate('/clinical_forms/role/doctor'):navigate(-1);
	};

	const handleUpdateSubFormList = (values, fieldindex) => {
		dispatch(changeSubFormList({ values, fieldindex, mode: 'edit' }));
	};
	const handleSubmitFormData = (values) => {
		let formdata = new FormData();
		formdata.append('form_id', formHistoryEditResponse.id);
		formdata.append('hospital_id', currentHospital.value);
		formdata.append('p_id', currentPatient.p_id);
		formdata.append('ip_no', currentPatient.ip_no);
		formdata.append('id', id);
		formdata.append('isArchived', isArchived);
		formdata.append('formdata[form_unique_id]', id /* formHistoryEditResponse.form_unique_id */);
		Object.entries(values).map((field) => {
			formdata.append('formdata[' + field[0] + ']', field[1]);
			formHistoryEditResponse.form_fields.map((formfield) => {
				if (formfield.field_name === field[0] && formfield.type === 'subform') {
					formdata.delete('formdata[' + field[0] + ']');
					formdata.append('formdata[' + field[0] + '][id]', formfield.sub_form_unique_id);
				}
				else if (formfield.field_name === field[0] && formfield.type === 'select') {
					if (!isString(field[1])) {
						formdata.delete('formdata[' + field[0] + ']');
						if (formfield.possible_values != '*') {
							formdata.append('formdata[' + field[0] + ']', field[1]?.value || '');
						}
						else {
							formdata.append('formdata[' + field[0] + ']', join(map(field[1], 'value'), ','));
						}
					}
				}
				else if (formfield.field_name === field[0] && formfield.type === 'checkbox') {
					formdata.delete('formdata[' + field[0] + ']');
					formdata.append('formdata[' + field[0] + ']', join(compact(field[1]), ','));
				}
				/* else if (formfield.field_name === field[0] && formfield.type === 'text' && formfield.autocomplete != '0') {
					formdata.delete('formdata[' + field[0] + ']');
					formdata.append('formdata[' + field[0] + ']', field[1]?.value || '');
				} */
			});
		});
		dispatch(updateForm(formdata));
	};

	return (
		<Container className='mt-4'>
			<Row className='my-3'>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>{formHistoryEditResponse.name}</h5>
					<BackButton onBack={goBack} />
				</Col>
			</Row>
			<hr />
			<Row className='mt-4'>
				<PatientInfo currentPatient={currentPatient} currentPatientDetail={currentPatientDetail} />
			</Row>
			{Loading &&
				<Row className='d-flex justify-content-center mt-4'>
					<Loader />
				</Row>
			}
			<Row className='mt-4'>
				<Col>
					{(formHistoryEditError || updateFormError) &&
						<Alert variant='danger'>
							<ul className='mb-0'>
								{formHistoryEditError && formHistoryEditError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
								{updateFormError && updateFormError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					<Container className='mb-3'>
						{t('CFORMS.COLUMNS.DATETIME')} : {getFormattedDateTime(formHistoryEditResponse?.date_time)}
					</Container>
					{formHistoryEditResponse.form_fields && <Card text='dark' bg='white'>
						<Card.Header className='text-uppercase'>
							{formHistoryEditResponse.name}
						</Card.Header>
						<Card.Body>
							{!isEmpty(initialValues) && <DynamicForm initialValues={initialValues} fields={formHistoryEditResponse.form_fields} handleCancel={goBack} handleSubmit={handleSubmitFormData} parentformuniqueid={id} handleUpdateSubFormList={handleUpdateSubFormList} loading={Loading} />
							}
						</Card.Body>
					</Card>}
				</Col>
			</Row>
		</Container >
	);
};

export default EditClinicalForm;
