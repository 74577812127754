import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Button, Col, Container, Form, InputGroup, Row, Stack, Tab, Table, Tabs } from 'react-bootstrap';
import { BsFillPencilFill, BsFillEyeFill, BsFillPrinterFill } from 'react-icons/bs';
import { FaDownload, FaSearch, FaTimes } from 'react-icons/fa';
import { isEmpty, split } from 'lodash';
import { t } from 'i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import BackButton from '../../components/BackButton';
import PatientInfo from '../../components/Patient/patient-info';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import Loader from '../../components/SplashScreen/loader';
import { reset } from '../../redux/forms/slice';
import { deleteDocument, getFormDocumentHistory, getFormHistory, getMedicinesHistory } from '../../redux/forms';
import { getPatientInfo } from '../../redux/hospital';
import useDebounce from '../../utils/useDebounceHook';
import { getDefaultFormID, getFormattedDateTime, getFormattedDate, downloadPDFFile } from '../../utils/helper';
import { IoMdClose } from 'react-icons/io';
import { callGetAPI } from '../../services/axios';

const HistoryClinicalForm = () => {
	const [key, setKey] = useState('digitalRecords');
	const [statDate, setStatDate] = useState(moment().format('YYYY-MM-DD'));
	const [filterText, setFilterText] = useState('');
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [filterTextDoc, setFilterTextDoc] = useState('');
	const [totalDocRows, setTotalDocRows] = useState(0);
	const [perPageDoc, setPerPageDoc] = useState(10);
	const [id, setId] = useState(null);
	const [deleteRecord, setDeleteRecord] = useState(null);
	const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
	const [downloadLoader, setDownloadLoader] = useState(false);
	const [deleteMessage, setDeleteMessage] = useState(null);
	const [columnSort, setColumnSort] = useState({});
	const iframeRef = useRef(null);
	const { currentPatient, currentPatientDetail, currentPatientIpno } = useSelector(state => state.hospital);
	const { formHistoryResponse, formHistroyError, formDocumentHistoryResponse, formDocumentHistroyError, formDocumentDeleteErrorMessage, formDocumentDeletedSuccess, downloadFormRecordDetail, downloadFormRecordDetailError, medicinesHistory, medicinesHistoryError, Loading } = useSelector(state => state.forms);
	const { currentHospital } = useSelector(state => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { formId } = useParams();
	const location = useLocation();
	const debouncedSearchTerm = useDebounce(filterText, 500);
	const debouncedSearchTermDoc = useDebounce(filterTextDoc, 500);
	const isArchived = location.pathname.startsWith('/archived_forms');

	const showDeleteModal = (data) => {
		setId(data.id);
		setDeleteRecord(data);
		setDeleteMessage('Are you sure you want to delete the record ?');
		setDisplayConfirmationModal(true);
	};

	useEffect(() => {
		if (formDocumentDeletedSuccess) {
			setDisplayConfirmationModal(false);
			dispatch(getFormDocumentHistory({ page: 1, per_page: perPageDoc, hospital_id: currentHospital.value, form_id: formId, p_id: currentPatient.p_id, ip_no: currentPatientIpno?.ip_no, bed_type: currentPatient.bed_type, ...columnSort }));
		}
		if (formDocumentDeleteErrorMessage) {
			setDisplayConfirmationModal(false);
			dispatch(getFormDocumentHistory({ page: 1, per_page: perPageDoc, hospital_id: currentHospital.value, form_id: formId, p_id: currentPatient.p_id, ip_no: currentPatientIpno?.ip_no, bed_type: currentPatient.bed_type, ...columnSort }));
		}
	}, [formDocumentDeletedSuccess, formDocumentDeleteErrorMessage]);

	useEffect(() => {
		if (!isEmpty(downloadFormRecordDetail) && downloadFormRecordDetail?.path != '') {
			const hiddenButton = document.getElementById('pdf');
			hiddenButton?.setAttribute('href', downloadFormRecordDetail?.path);
			hiddenButton?.click();
		}
	}, [downloadFormRecordDetail]);

	useEffect(() => {
		if (isArchived) {
			if (!isEmpty(currentPatient)) {
				if (currentPatient.ip_no !== currentPatientDetail?.patient_info?.panel_title?.ip_no || isEmpty(currentPatientDetail)) {
					dispatch(getPatientInfo({ hospital_id: currentHospital.value, p_id: currentPatient.id, ip_no: currentPatient.ip_no }));
				}
			} else {
				navigate(-1);
			}
		} else if (isEmpty(currentPatient)) {
			navigate('/');
		} else {
			if (isEmpty(currentPatientDetail) || (currentPatient.p_id !== currentPatientDetail?.patient_info?.panel_title?.id)) {
				dispatch(getPatientInfo({ hospital_id: currentHospital.value, p_id: currentPatient.p_id, ip_no: currentPatientIpno?.ip_no }));
			}
		}
	}, [currentPatient, currentPatientDetail]);

	useEffect(() => {
		if (key == 'digitalRecords' && !isEmpty(currentPatient) && !isEmpty(statDate)) {
			const ipNo = isArchived ? currentPatient?.ip_no : currentPatientIpno?.ip_no;
			if (location?.state?.defaultId == getDefaultFormID('Drug Administration Orders(Nurse)')) {
				dispatch(getMedicinesHistory({ hospital_id: currentHospital.value, p_id: currentPatient.p_id, ip_no: ipNo, date: statDate }));
			}
			else if (!isEmpty(currentHospital) && debouncedSearchTerm == '') {
				dispatch(getFormHistory({ page: 1, per_page: perPage, hospital_id: currentHospital.value, form_id: formId, p_id: currentPatient.p_id, ip_no: ipNo, ...columnSort, isArchived }));
			}
			else if (!isEmpty(currentHospital) && debouncedSearchTerm !== '') {
				dispatch(getFormHistory({ page: 1, per_page: perPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, form_id: formId, p_id: currentPatient.p_id, ip_no: ipNo, ...columnSort, isArchived }));
			}
		}
		else if (key == 'uploadedDocuments' && !isEmpty(currentPatient)) {
			if (!isEmpty(currentHospital) && debouncedSearchTermDoc == '') {
				dispatch(getFormDocumentHistory({ page: 1, per_page: perPageDoc, hospital_id: currentHospital.value, form_id: formId, p_id: currentPatient.p_id, ip_no: currentPatientIpno?.ip_no, bed_type: currentPatient.bed_type, ...columnSort }));
			}
			else if (!isEmpty(currentHospital) && debouncedSearchTermDoc !== '') {
				dispatch(getFormDocumentHistory({ page: 1, per_page: perPageDoc, hospital_id: currentHospital.value, search: debouncedSearchTermDoc, bed_type: currentPatient.bed_type, form_id: formId, p_id: currentPatient.p_id, ip_no: currentPatientIpno?.ip_no, ...columnSort }));
			}
		}
	}, [currentHospital, debouncedSearchTerm, key, debouncedSearchTermDoc, columnSort, currentPatient, statDate]);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(formDocumentDeleteErrorMessage) || !isEmpty(downloadFormRecordDetailError) || !isEmpty(formHistroyError) || !isEmpty(formDocumentHistroyError)) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	}, [formDocumentDeleteErrorMessage, downloadFormRecordDetailError, formHistroyError, formDocumentHistroyError]);

	useEffect(() => {
		if (!isEmpty(formHistoryResponse)) {
			setTotalRows(formHistoryResponse.total);
			setPerPage(formHistoryResponse.per_page);
		}
	}, [formHistoryResponse]);

	useEffect(() => {
		if (!isEmpty(formDocumentHistoryResponse)) {
			setTotalDocRows(formDocumentHistoryResponse.total);
			setPerPageDoc(formDocumentHistoryResponse.per_page);
		}
	}, [formDocumentHistoryResponse]);

	// Hide the modal
	const hideConfirmationModal = () => {
		setDisplayConfirmationModal(false);
	};

	// Handle the actual deletion of the item
	const submitDelete = (deleteRecord) => {
		dispatch(deleteDocument({ id: deleteRecord.id, hospital_id: currentHospital.value, form_id: formId, p_id: currentPatient.p_id }));
	};

	const handlePageChange = page => {
		dispatch(getFormHistory({ page: page, per_page: perPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, form_id: formId, p_id: currentPatient.p_id, ...columnSort }));
	};
	const handlePerRowsChange = async(newPerPage, page) => {
		dispatch(getFormHistory({ page: page, per_page: newPerPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, form_id: formId, p_id: currentPatient.p_id, ...columnSort }));
	};
	const handleDocumentPageChange = page => {
		dispatch(getFormDocumentHistory({ page: page, per_page: perPageDoc, hospital_id: currentHospital.value, search: debouncedSearchTermDoc, form_id: formId, p_id: currentPatient.p_id, bed_type: currentPatient.bed_type, ...columnSort }));
	};
	const handleDocumentPerRowsChange = async(newPerPage, page) => {
		dispatch(getFormDocumentHistory({ page: page, per_page: newPerPage, hospital_id: currentHospital.value, search: debouncedSearchTermDoc, form_id: formId, p_id: currentPatient.p_id, bed_type: currentPatient.bed_type, ...columnSort }));
	};
	const handleDownloadFormRecord = (row, isDownload) => {
		setDownloadLoader(true);
		callGetAPI({
			route: 'hospital/form/download/details',
			params: { hospital_id: currentHospital.value, p_id: currentPatient.p_id, form_id: formId, id: row.id, bed_type: currentPatient.bed_type },
			options: {
				responseType: 'blob'
			}
		}).then((data) => {
			if (isDownload) {
				downloadPDFFile(data.data);
				return;
			}
			const blob = new Blob([data?.data], { type: 'application/pdf' });
			const pdfBlobUrl = URL.createObjectURL(blob);

			if (iframeRef.current) {
				iframeRef.current.src = pdfBlobUrl;

				setTimeout(() => {
					if (iframeRef.current && iframeRef.current.contentWindow) {
						iframeRef.current.contentWindow.print();
					}
				}, 500);
			}
		}).finally(() => {
			setDownloadLoader(false);
		});
		// dispatch(downloadFormRecord({ hospital_id: currentHospital.value, p_id: currentPatient.p_id, form_id: formId, id: row.id, bed_type: currentPatient.bed_type }));
	};

	const columns = [
		{
			name: t('CFORMS.COLUMNS.DATETIME'),
			selector: row => {
				const date = getFormattedDateTime(row.date_time);
				return `${date} by ${row?.user?.first_name ?? ''} ${row?.user?.last_name ?? ''}`;
			},
			sortable: true,
			sortField: 'date_time',
		},
		{
			name: t('CFORMS.COLUMNS.ACTIONS'),
			cell: row => (
				<Stack direction='horizontal' gap='2'>
					{(currentPatientIpno?.discharged_at === null || isArchived) && <Button title={t('GENERAL.EDIT')} size="sm" as={Link} to={`/${isArchived ? 'archived_forms' : 'clinical_forms'}/form/${formId}/edit/${row.id}`}>
						<BsFillPencilFill /> {t('GENERAL.EDIT')}
					</Button>}
					{!isArchived && <><Button title={t('GENERAL.VIEW')} size="sm" as={Link} to={`/clinical_forms/form/${formId}/view/${row.id}`}>
						<BsFillEyeFill /> {t('GENERAL.VIEW')}
					</Button><Button title={t('GENERAL.DOWNLOAD')} disabled={downloadLoader} size="sm" onClick={() => handleDownloadFormRecord(row, true)}>
						<FaDownload /> {t('GENERAL.DOWNLOAD')}
					</Button><Button title={t('GENERAL.PRINT')} disabled={downloadLoader} size="sm" onClick={() => handleDownloadFormRecord(row)}>
						<BsFillPrinterFill /> {t('GENERAL.PRINT')}
					</Button></>}
				</Stack>
			),
		},
	];

	const documentColumns = [
		{
			name: t('CFORMS.COLUMNS.DATE'),
			selector: row => getFormattedDate(row.date),
			sortable: true,
			sortField: 'date_time',
		},
		{
			name: t('CFORMS.COLUMNS.NAME'),
			selector: row => row.name,
			sortable: true,
			sortField: 'name',
		},
		{
			name: t('CFORMS.COLUMNS.TYPE'),
			selector: row => row.type,
			sortable: true,
			sortField: 'type',
		},
		{
			name: t('CFORMS.COLUMNS.ACTIONS'),
			grow: '2',
			cell: row => (
				<Stack direction='horizontal' gap='2'>
					<a title={t('GENERAL.VIEW')} className='btn btn-primary btn-sm' href={row.doc_url} rel="noreferrer" target='_blank'>
						<BsFillEyeFill /> {t('GENERAL.VIEW')}
					</a>
					<a title={t('GENERAL.DOWNLOAD')} className='btn btn-primary btn-sm' href={row.doc_url} rel="noreferrer" target='_blank' download>
						<FaDownload /> {t('GENERAL.DOWNLOAD')}
					</a>
					{currentPatientIpno?.discharged_at === null &&
					<Button title={t('GENERAL.DELETE')} size="sm" variant='danger' onClick={() => showDeleteModal(row)}>
						<FaTimes /> {t('GENERAL.DELETE')}
					</Button>}
				</Stack>
			),
		},

	];

	const subHeaderComponentMemo = useMemo(() => {
		return (
			<Col md={2}>
				<InputGroup controlId="formGridSearchText">
					{/* <InputGroup.Text className="d-block d-xl-none">{t('GENERAL.DATE')}</InputGroup.Text> */}
					<Form.Control
						type="date"
						placeholder={t('GENERAL.SEARCHTEXT')}
						name="search_text"
						value={filterText}
						min={moment(currentPatientIpno?.date_of_admission).format(moment.HTML5_FMT.DATE)}
						max={currentPatientIpno?.discharged_at === null ? moment(currentPatient?.current_date).format(moment.HTML5_FMT.DATE) : moment(currentPatientIpno?.discharged_at).format(moment.HTML5_FMT.DATE)}
						onChange={(e) => {
							setFilterText(e.target.value);
						}} />
					<Button size='sm' variant='outline-secondary' onClick={() => setFilterText('')}>
						<IoMdClose />
					</Button>
				</InputGroup>
			</Col>
		);
	}, [filterText]);

	const subHeaderComponentMemoDocument = useMemo(() => {
		return (
			<Col md={2}>
				<InputGroup controlId="formGridSearchText">
					{/* <InputGroup.Text>{t('GENERAL.DATE')}</InputGroup.Text> */}
					<Form.Control
						type="date"
						placeholder={t('GENERAL.SEARCHTEXT')}
						name="search_text"
						value={filterTextDoc}
						min={moment(currentPatientIpno?.date_of_admission).format(moment.HTML5_FMT.DATE)}
						max={currentPatientIpno?.discharged_at === null ? moment(currentPatient?.current_date).format(moment.HTML5_FMT.DATE) : moment(currentPatientIpno?.discharged_at).format(moment.HTML5_FMT.DATE)}
						onChange={(e) => {
							setFilterTextDoc(e.target.value);
						}} />
					<Button size='sm' variant='outline-secondary' onClick={() => setFilterTextDoc('')}>
						<IoMdClose />
					</Button>
				</InputGroup>
			</Col>
		);
	}, [filterTextDoc]);

	const handleViewMedicines = () => {
		dispatch(getMedicinesHistory({ hospital_id: currentHospital.value, p_id: currentPatient.p_id, date: statDate }));
	};

	const handleSort = async(column, sortDirection) => {
		setColumnSort({ column_name: column.sortField, order: sortDirection });
	};

	return (
		<Container className='mt-4'>
			<iframe
				ref={iframeRef}
				style={{ display: 'none' }}
				title="PDF"
			/>
			<Row className='my-3'>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>{isArchived ? t('CFORMS.ARCHIVEOF') : t('CFORMS.HISTORYOF')} {formHistoryResponse?.form_info?.form_name || location?.state?.formName}</h5>
					<BackButton />
				</Col>
			</Row>
			<hr />
			<Row className='mt-4'>
				<PatientInfo currentPatient={currentPatient} currentPatientDetail={currentPatientDetail} />
			</Row>
			{/* {Loading &&
				<Row className='d-flex justify-content-center mt-4'>
					<Loader />
				</Row>} */}
			<Row className='mt-4'>
				<Col>
					{(formHistroyError || formDocumentHistroyError || formDocumentDeleteErrorMessage || downloadFormRecordDetailError || medicinesHistoryError) &&
						<Alert variant='danger'>
							<ul className='mb-0'>
								{formHistroyError && formHistroyError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
								{formDocumentHistroyError && formDocumentHistroyError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
								{formDocumentDeleteErrorMessage && formDocumentDeleteErrorMessage.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
								{downloadFormRecordDetailError && downloadFormRecordDetailError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
								{medicinesHistoryError && medicinesHistoryError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>
					}
					<div className='border border-2 border-top-0 shadow'>
						<Tabs
							id="controlled-tab-example"
							activeKey={key}
							onSelect={(k) => setKey(k)}
							className="mb-3">
							<Tab eventKey="digitalRecords" title={t('CFORMS.DIGITALRECORDD')}>
								{location?.state?.defaultId != getDefaultFormID('Drug Administration Orders(Nurse)') ? <DataTable
									title={t('CFORMS.DIGITALRECORDD')}
									columns={columns}
									data={formHistoryResponse?.history?.data}
									progressPending={Loading}
									progressComponent={<Loader />}
									highlightOnHover
									pagination
									paginationServer
									paginationTotalRows={totalRows}
									onChangeRowsPerPage={handlePerRowsChange}
									onChangePage={handlePageChange}
									subHeader
									subHeaderComponent={subHeaderComponentMemo}
									onSort={handleSort}
								/> :
									<>
										<Row className='m-3 justify-content-center'>
											<Col md={4}>
												<InputGroup className="mb-3">
													<Form.Control
														type='date'
														name='chartDate'
														value={statDate}
														min={moment(currentPatientIpno?.date_of_admission).format(moment.HTML5_FMT.DATE)}
														max={currentPatientIpno?.discharged_at === null ? moment(currentPatient?.current_date).format(moment.HTML5_FMT.DATE) : moment(currentPatientIpno?.discharged_at).format(moment.HTML5_FMT.DATE)}
														onChange={(e) => {
															setStatDate(e.target.value);
														}} />
													<Button title={t('GENERAL.SEARCH')} onClick={handleViewMedicines}><FaSearch /></Button>
												</InputGroup>
											</Col>
										</Row>
										{!isEmpty(medicinesHistory) &&
											<Row className='m-3 justify-content-center'>
												<Col>
													{!isEmpty(medicinesHistory) && medicinesHistory?.map((blocks, blocksIndex) =>
														<>
															<div className="bg-primary text-white fw-bold mt-3 px-4 py-1">{blocks?.title}</div>
															{!isEmpty(blocks.values) &&
																<Table key={blocksIndex} bordered hover responsive size="sm" className='medicine-table'>
																	<thead>
																		<tr>
																			{Object.entries(blocks.values[0])?.map(([header, headerVal], headerIndex) =>
																				<th key={headerIndex} data={headerVal}>{header}</th>
																			)}
																		</tr>
																	</thead>
																	<tbody>
																		{!isEmpty(blocks.values) && blocks.values?.map((row, rowIndex) =>
																			<tr key={rowIndex}>
																				{!isEmpty(row) && Object.entries(row)?.map(([recordKey, recordValue], recordIndex) =>
																					<td key={recordIndex} data={recordKey}>
																						{
																							split(recordValue, ',')?.map((val) => {
																								return (
																									<>
																										{val + ' '}<br />
																									</>
																								);
																							})}
																					</td>
																				)}
																			</tr>
																		)}
																	</tbody>
																</Table>}
														</>
													)}
												</Col>
											</Row>
										}
									</>
								}
							</Tab>
							{!isArchived && <Tab eventKey="uploadedDocuments" title={t('CFORMS.UPLOADEDDOC')}>
								<DataTable
									title={t('CFORMS.UPLOADEDDOC')}
									columns={documentColumns}
									data={formDocumentHistoryResponse?.data}
									progressPending={Loading}
									progressComponent={<Loader />}
									highlightOnHover
									pagination
									paginationServer
									paginationTotalRows={totalDocRows}
									onChangeRowsPerPage={handleDocumentPerRowsChange}
									onChangePage={handleDocumentPageChange}
									subHeader
									subHeaderComponent={subHeaderComponentMemoDocument}
									onSort={handleSort}
									sortServer
								/>
							</Tab>}
						</Tabs>
					</div>
					<DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} id={id} deleteRecord={deleteRecord} message={deleteMessage} title={t('GENERAL.DELETETITLE')} buttonText={t('GENERAL.DELETE')} loading={Loading} />
				</Col>
			</Row>
		</Container >
	);
};

export default HistoryClinicalForm;
