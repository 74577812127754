import React, { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { Alert, Button, Col, Container, Form, Row, Stack, Tab, Tabs } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import Loader from '../../components/SplashScreen/loader';
import { getAdmittedPatientList, getPatientList } from '../../redux/patients';
import { reset } from '../../redux/patients/slice';
import useDebounce from '../../utils/useDebounceHook';
import HelpVideoButton from '../../components/HelpVideoButton';
import { IoMdClose } from 'react-icons/io';
import { callPostAPI } from '../../services/axios';
import DeleteConfirmation from '../../components/DeleteConfirmation';

const Patients = () => {
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [cancelAdmissionModel, setCancelAdmissionModel] = useState(null);
	const [totalAdmittedRows, setTotalAdmittedRows] = useState(0);
	const [perPageAdmitted, setPerPageAdmitted] = useState(10);
	const [successMessage, setSuccessMessage] = useState(null);
	const [errorMessage, setErrorMessage] = useState('');
	const [key, setKey] = useState('unadmitted');
	const [columnSort, setColumnSort] = useState({});
	const [filterText, setFilterText] = useState('');
	const [filterTextAdmitted, setFilterTextAdmitted] = useState('');
	const dispatch = useDispatch();
	const { currentHospital } = useSelector(state => state.auth);
	const { hospitalBedInfo } = useSelector(state => state.hospital);
	const { patientListError, patientList, admittedPatientList, admittedPatientListError, Loading } = useSelector(state => state.patients);
	const debouncedAdmittedSearchTerm = useDebounce(filterTextAdmitted, 500);
	const debouncedSearchTerm = useDebounce(filterText, 500);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(patientList)) {
			setTotalRows(patientList.total);
			setPerPage(patientList.per_page);
			setSuccessMessage(patientList.message);
		}
	}, [patientList]);

	useEffect(() => {
		if (patientListError) {
			setErrorMessage(patientListError);
		}
	}, [patientListError]);

	useEffect(() => {
		if (key == 'unadmitted') {
			if (!isEmpty(currentHospital) && debouncedSearchTerm == '') {
				dispatch(getPatientList({ page: 1, per_page: perPage, hospital_id: currentHospital.value, ...columnSort }));
			}
			else if (!isEmpty(currentHospital) && debouncedSearchTerm !== '') {
				dispatch(getPatientList({ page: 1, per_page: perPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
			}
		}
	}, [currentHospital, debouncedSearchTerm, key, columnSort]);

	useEffect(() => {
		if (!isEmpty(admittedPatientList)) {
			setTotalAdmittedRows(admittedPatientList.total);
			setPerPageAdmitted(admittedPatientList.per_page);
			setSuccessMessage(admittedPatientList.message);
		}
	}, [admittedPatientList]);

	useEffect(() => {
		if (admittedPatientListError) {
			setErrorMessage(admittedPatientListError);
		}
	}, [admittedPatientListError]);

	useEffect(() => {
		callingAdmittedPatientListAPI();
	}, [currentHospital, debouncedAdmittedSearchTerm, key, columnSort]);

	const callingAdmittedPatientListAPI = () => {
		if (key == 'icu' || key == 'ward') {
			if (!isEmpty(currentHospital) && debouncedAdmittedSearchTerm == '') {
				dispatch(getAdmittedPatientList({ page: 1, per_page: perPageAdmitted, hospital_id: currentHospital.value, is_admitted: 'yes', bed_type: key, ...columnSort }));
			}
			else if (!isEmpty(currentHospital) && debouncedAdmittedSearchTerm !== '') {
				dispatch(getAdmittedPatientList({ page: 1, per_page: perPageAdmitted, hospital_id: currentHospital.value, is_admitted: 'yes', bed_type: key, search: debouncedAdmittedSearchTerm, ...columnSort }));
			}
		}
	};

	const subHeaderComponentMemo = useMemo(() => {
		return (
			<Form.Group as={Row} controlId="formGridSearchText">
				<Form.Control
					type="text"
					placeholder={t('GENERAL.SEARCHTEXT')}
					name="search_text"
					value={filterText}
					onChange={(e) => {
						setFilterText(e.target.value);
					}} />
			</Form.Group>
		);
	}, [filterText]);


	const subHeaderAdmittedComponentMemo = useMemo(() => {
		return (
			<Form.Group as={Row} controlId="formGridSearchTextAdmitted">
				<Form.Control
					type="text"
					placeholder={t('GENERAL.SEARCHTEXT')}
					name="search_text_admitted"
					value={filterTextAdmitted}
					onChange={(e) => {
						setFilterTextAdmitted(e.target.value);
					}} />
			</Form.Group>
		);
	}, [filterTextAdmitted]);

	//UnAdmitted Patient
	const actions = (
		<>
			<HelpVideoButton videoKey={'add_patient'} />
			<Link title={t('PATIENTS.BUTTON.ADDUSER')} className='btn btn-primary btn-sm' to='add'>
				<HiPlus /> {t('PATIENTS.BUTTON.ADDUSER')}
			</Link>
		</>
	);

	const columns = [
		{
			name: t('PATIENTS.COLUMNS.REGNO'),
			cell: row => row.reg_no,
			sortable: true,
			sortField: 'reg_no',
		},
		{
			name: t('PATIENTS.COLUMNS.NAME'),
			selector: row => `${row.first_name} ${row.last_name}`,
			sortable: true,
			sortField: 'first_name',
		},
		{
			name: t('PATIENTS.COLUMNS.MOBILE'),
			selector: row => row.mobile,
			sortable: true,
			sortField: 'mobile',
		},
		{
			name: t('GENERAL.ACTION'),
			cell: row => (
				<>
					<Stack gap={2} direction='horizontal'>
						<Link title={t('GENERAL.VIEWDETAILS')} className='btn btn-sm btn-success' to={`viewdetails/${row.id}`}>
							{t('GENERAL.VIEWDETAILS')}
						</Link>
						{!(row?.is_admitted) && <Link title={t('GENERAL.ADMIT_PATIENT')} className='btn btn-sm btn-primary' to={`detail/${row.id}`}>
							{t('GENERAL.ADMIT_PATIENT')}
						</Link>}
					</Stack>
				</>
			)
		},

	];
	const handlePageChange = page => {
		if (!isEmpty(currentHospital)) {
			dispatch(getPatientList({ page: page, per_page: perPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
		}
	};

	const handlePerRowsChange = async(newPerPage, page) => {
		if (!isEmpty(currentHospital)) {
			dispatch(getPatientList({ page: page, per_page: newPerPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
		}
	};

	const columnsAdmitted = useMemo(() => {
		const column = [
			{
				name: t('PATIENTS.COLUMNS.REGNO'),
				cell: row => (
					<Link title={t('PATIENTS.COLUMNS.REGNO')} to={`/admittedpatients/detail/${row.id}`}>
						{row.reg_no}
					</Link>
				),
				sortable: true,
				sortField: 'reg_no',
			},
			{
				name: t('PATIENTS.COLUMNS.NAME'),
				selector: row => `${row.first_name} ${row.last_name}`,
				sortable: true,
				sortField: 'first_name',
			},
			{
				name: t('PATIENTS.COLUMNS.MOBILE'),
				selector: row => row.mobile,
				sortable: true,
				sortField: 'mobile',
			},
			{
				name: t('PATIENTS.COLUMNS.BEDNO'),
				selector: row => row.bed_no,
				sortable: true,
				sortField: 'bed_no',
			},
		];

		if (currentHospital?.is_admin === 'yes') {
			column.push({
				name: t('GENERAL.ACTION'),
				cell: (row) => (
					<Stack gap={2} direction='horizontal'>
						<Button title={t('PATIENTS.CANCEL_ADMISSION')} variant="danger" size="sm" onClick={() => setCancleAdmissionModelData(row.id)}>
							<IoMdClose /> {t('PATIENTS.CANCEL_ADMISSION')}
						</Button>
					</Stack>
				)
			});
		}
		return column;
	}, [currentHospital, key]);

	const setCancleAdmissionModelData = (id) => {
		setCancelAdmissionModel({
			id,
			show: true,

		});
	};

	const resetCancelAdmissionModel = () => {
		setCancelAdmissionModel(null);
	};

	const handleCancleAdmission = async() => {
		if (!cancelAdmissionModel.id || !currentHospital.value) {
			return;
		}
		try {
			await callPostAPI({ route: 'hospital/patients/cancel/admission', body: {
				hospital_id: currentHospital.value,
				id: cancelAdmissionModel.id,
			}});
			callingAdmittedPatientListAPI();
		} finally {
			resetCancelAdmissionModel();
		}
	};

	const handleAdmittedPageChange = page => {
		dispatch(getAdmittedPatientList({ page: page, per_page: perPageAdmitted, hospital_id: currentHospital.value, search: debouncedSearchTerm, is_admitted: 'yes', bed_type: key, ...columnSort }));
	};

	const handleAdmittedPerRowsChange = async(newPerPage, page) => {
		if (!isEmpty(currentHospital)) {
			dispatch(getAdmittedPatientList({ page: page, per_page: newPerPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, is_admitted: 'yes', bed_type: key, ...columnSort }));
		}
	};

	const handleSort = async(column, sortDirection) => {
		setColumnSort({ column_name: column.sortField, order: sortDirection });
	};

	const renderDataTable = (title, columns, data, totalRows, handlePerRowsChange, handlePageChange, subHeaderComponentMemo, handleSort, actions) => (
		<DataTable
			title={title}
			columns={columns}
			actions={actions}
			data={data}
			progressPending={Loading}
			progressComponent={<Loader />}
			highlightOnHover
			pagination
			paginationServer
			paginationTotalRows={totalRows}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
			subHeader
			subHeaderComponent={subHeaderComponentMemo}
			onSort={handleSort}
			sortServer
		/>
	);

	const renderTabs = () => (
		<Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
			<Tab eventKey="unadmitted" title={t('PATIENTS.TITLE')}>
				{renderDataTable(t('PATIENTS.TITLE'), columns, patientList.data, totalRows, handlePerRowsChange, handlePageChange, subHeaderComponentMemo, handleSort, actions)}
			</Tab>
			{hospitalBedInfo?.total_icu_beds > 0 && (
				<Tab eventKey="icu" title={t('PATIENTS.PATIENT_EDIT_ADMISSION_DETAILS_ICU')}>
					{renderDataTable(t('PATIENTS.PATIENT_EDIT_ADMISSION_DETAILS_ICU'), columnsAdmitted, admittedPatientList.data, totalAdmittedRows, handleAdmittedPerRowsChange, handleAdmittedPageChange, subHeaderAdmittedComponentMemo, handleSort)}
				</Tab>
			)}
			{hospitalBedInfo?.total_ward_beds > 0 && (
				<Tab eventKey="ward" title={t('PATIENTS.PATIENT_EDIT_ADMISSION_DETAILS_WARD')}>
					{renderDataTable(t('PATIENTS.PATIENT_EDIT_ADMISSION_DETAILS_WARD'), columnsAdmitted, admittedPatientList.data, totalAdmittedRows, handleAdmittedPerRowsChange, handleAdmittedPageChange, subHeaderAdmittedComponentMemo, handleSort)}
				</Tab>
			)}
		</Tabs>
	);

	const renderPatientDetails = () => {
		if (currentHospital.is_admin == 'yes' || ['nurse', 'co-ordinator'].includes(currentHospital.role)) {
			return (
				<div className='border border-2 border-top-0 shadow'>
					{renderTabs()}
				</div>
			);
		}

		return (
			<Row>
				<Col>
					{renderDataTable(t('PATIENTS.TITLE'), columns, patientList.data, totalRows, handlePerRowsChange, handlePageChange, subHeaderComponentMemo, handleSort, actions)}
				</Col>
			</Row>
		);
	};

	return (
		<Container className='mt-4'>
			{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
			{successMessage && <Alert variant="success">{successMessage}</Alert>}
			{renderPatientDetails()}
			<DeleteConfirmation
				showModal={cancelAdmissionModel?.show}
				confirmModal={handleCancleAdmission}
				hideModal={resetCancelAdmissionModel}
				id={cancelAdmissionModel?.id}
				deleteRecord={null}
				message={'Are you sure you want to cancel admission?'}
				title={t('GENERAL.CANCEL_ADMISSION_COM')}
				buttonText={t('PATIENTS.CANCEL_ADMISSION')}
			/>
		</Container>
	);
};

export default Patients;
