import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Alert, Button, Card, Col, Container, Modal, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { FaChartPie, FaDownload, FaDropbox, FaEdit, FaGoogleDrive, FaPhoneAlt, FaUserAlt, FaExternalLinkAlt } from 'react-icons/fa';
import { HiChatBubbleLeftRight } from 'react-icons/hi2';
import BackButton from '../../components/BackButton';
import Loader from '../../components/SplashScreen/loader';
import { getArchivedFormDownload, getArchivedFormList, getArchivedChartDataDownload, getArchivedChatDataDownload, getArchivedChartGraphDownload } from '../../redux/archived-patients';
import { reset, resetArchivedChartDataDownload, resetArchivedChartGraphDownload, resetArchivedChatDataDownload, resetArchivedFormDownload } from '../../redux/archived-patients/slice';
import { getDefaultFormID, getFormattedDateTime } from '../../utils/helper';
import { getBackup } from '../../redux/auth';
import { now } from 'moment';
import { changePatient, changePatientIpno } from '../../redux/hospital/slice';
import { BsFillPencilFill } from 'react-icons/bs';
import { callGetAPI } from '../../services/axios';
import DynamicForm from '../../components/DynamicForm';
import { dischargeCheckList } from '../../redux/discharge-clinical';

const ArchivedForms = () => {
	const dispatch = useDispatch();
	const { id, ipno } = useParams();
	const { currentHospital, currentBackupSetting } = useSelector(state => state.auth);
	const { currentPatient } = useSelector(state => state.hospital);
	const [checklistModel, setChecklistModel] = useState({});
	const { archivedPatientFormList,
		archivedPatientFormListError,
		archivedFormDownload,
		archivedFormDownloadError,
		archivedFormDownloadLoading,
		archivedChartDataDownloadLoading,
		archivedChartDataDownload,
		archivedChartDataDownloadError,
		archivedChatDataDownloadLoading,
		archivedChatDataDownload,
		archivedChatDataDownloadError,
		archivedChartGraphDownloadLoading,
		archivedChartGraphDownload,
		archivedChartGraphDownloadError,
		Loading } = useSelector(state => state.archivedPatient);

	const { dischargeCheckListErrorMessage } = useSelector(state => state.dischargeClinical);
	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(currentHospital)) {
			dispatch(getArchivedFormList({ p_id: id, ip_no: ipno, hospital_id: currentHospital.value }));
			dispatch(getBackup({ hospital_id: currentHospital.value }));
		}
	}, [currentHospital]);

	useEffect(() => {
		if (!isEmpty(archivedPatientFormList)) {
			const patientInfo = archivedPatientFormList.patient_info;
			const currentPatientDetail = {
				...patientInfo,
				p_id: patientInfo.id,
				patient_name: patientInfo.name
			};
			dispatch(changePatient(currentPatientDetail));
			dispatch(changePatientIpno(currentPatientDetail));
		}
	}, [archivedPatientFormList]);

	useEffect(() => {
		if (!isEmpty(archivedPatientFormListError) || !isEmpty(archivedFormDownloadError) || !isEmpty(archivedChartDataDownloadError)) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	}, [archivedPatientFormListError, archivedFormDownloadError, archivedChartDataDownloadError]);

	useEffect(() => {
		if (!isEmpty(archivedFormDownload)) {
			downloadPDFFile(archivedFormDownload?.data);
			dispatch(resetArchivedFormDownload());
		}
		if (!isEmpty(archivedChartDataDownload)) {
			downloadPDFFile(archivedChartDataDownload.data);
			dispatch(resetArchivedChartDataDownload());
		}
		if (!isEmpty(archivedChartGraphDownload)) {
			downloadPDFFile(archivedChartGraphDownload.data);
			dispatch(resetArchivedChartGraphDownload());
		}
		if (!isEmpty(archivedChatDataDownload)) {
			downloadPDFFile(archivedChatDataDownload.data);
			dispatch(resetArchivedChatDataDownload());
		}
	}, [archivedFormDownload, archivedChartDataDownload, archivedChartGraphDownload, archivedChatDataDownload]);

	const openBackupLink = (backupLink) => {
		window.open(backupLink, '_blank');
	};
	const downloadHistory = (formId) => {
		dispatch(getArchivedFormDownload({ p_id: id, ip_no: ipno, hospital_id: currentHospital.value, form_id: formId }));
	};
	const downloadChartFile = () => {
		if (isEmpty(archivedPatientFormList?.chart_data_link)) {
			dispatch(getArchivedChartDataDownload({ p_id: id, ip_no: ipno, hospital_id: currentHospital.value }));
		}
		else {
			window.open(archivedPatientFormList?.chart_data_link, '_blank');
		}
	};
	const downloadChartGraphFile = () => {
		if (isEmpty(archivedPatientFormList?.chart_graph_link)) {
			dispatch(getArchivedChartGraphDownload({ p_id: id, ip_no: ipno, hospital_id: currentHospital.value }));
		}
		else {
			window.open(archivedPatientFormList?.chart_graph_link, '_blank');
		}
	};
	const downloadChatFile = () => {
		if (isEmpty(archivedPatientFormList?.chat_link)) {
			dispatch(getArchivedChatDataDownload({ p_id: id, ip_no: ipno, hospital_id: currentHospital.value }));
		}
		else {
			window.open(archivedPatientFormList?.chat_link, '_blank');
		}
	};

	const handleBackupExplorer = () => {
		if (currentBackupSetting && currentBackupSetting.type !== '') {
			if (currentBackupSetting.type == 'google') {
				window.open('https://drive.google.com/drive/my-drive', '_blank');
			}
			else {
				window.open('https://www.dropbox.com/home', '_blank');
			}
		}
	};

	const editFormUrl = (form) => {
		if (form.default_form_id === getDefaultFormID('Discharge Summary')) {
			return `/archived_forms/discharge-summary?patientId=${currentPatient.id}&ipNo=${currentPatient.ip_no}`;
		}
		return `/archived_forms/form/${form.id}/list`;
	};

	const handleDischageChecklistForm = async(form) => {
		await callGetAPI({
			route: 'hospital/discharge/admin/checklist/details',
			params: {
				hospital_id: currentHospital.value,
				p_id: id,
				ip_no: ipno,
				form_id: form.id
			},
		}).then(({ data }) => {
			setChecklistModel({
				fields: data.data.fields,
				dsId: data.data.dsId,
				value: data.data.value
			});
		});

	};

	const editArchivedForm  = (form) => {
		if (currentHospital.is_admin !== 'yes') {
			return;
		}
		if (form.default_form_id === getDefaultFormID('Dischage Checklist')) {
			return (<Button title={t('GENERAL.EDIT')} size="sm" onClick={() => handleDischageChecklistForm(form)}>
				<BsFillPencilFill /> {t('GENERAL.EDIT')}
			</Button>);
		}
		return (
			<Button title={t('GENERAL.EDIT')} size="sm" as={Link} to={editFormUrl(form)}>
				<BsFillPencilFill /> {t('GENERAL.EDIT')}
			</Button>
		);
	};

	const handleSubmitFormData = (values) => {
		let formdata = new FormData();
		formdata.append('hospital_id', currentHospital.value);
		formdata.append('p_id', id);
		formdata.append('ip_no', ipno);
		formdata.append('isArchived', true);
		Object.entries(values).map((field) => {
			formdata.append('formdata[' + field[0] + ']', field[1]);
		});
		dispatch(dischargeCheckList(formdata)).then(() => {
			setChecklistModel({});
		});
	};

	const downloadPDFFile = (data) => {
		const url = URL.createObjectURL(data);

		const a = document.createElement('a');
		a.download = now() + '.pdf';
		a.href = url;
		a.target = '_self';
		a.click();

		setTimeout(() => {
			a.remove();
			URL.revokeObjectURL(url);
		}, 100);
	};
	return (
		<Container className='mt-4'>
			{(archivedPatientFormListError || archivedFormDownloadError || archivedChartDataDownloadError || archivedChartGraphDownloadError || archivedChatDataDownloadError) &&
				<Alert variant='danger'>
					<ul className='mb-0'>
						{archivedPatientFormListError && archivedPatientFormListError.split('|').map((error, index) => (
							<li key={index}>{error}</li>
						))}
						{archivedFormDownloadError && archivedFormDownloadError.split('|').map((error, index) => (
							<li key={index}>{error}</li>
						))}
						{archivedChartDataDownloadError && archivedChartDataDownloadError.split('|').map((error, index) => (
							<li key={index}>{error}</li>
						))}
						{archivedChatDataDownloadError && archivedChatDataDownloadError.split('|').map((error, index) => (
							<li key={index}>{error}</li>
						))}
						{archivedChartGraphDownloadError && archivedChartGraphDownloadError.split('|').map((error, index) => (
							<li key={index}>{error}</li>
						))}
					</ul>
				</Alert>}
			<Row className='my-3'>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>{t('ARCHIVEDFORMS.TITLE')}</h5>
					<div>
						{currentBackupSetting && currentBackupSetting.type !== '' && <Button title={t('GENERAL.BACKUPEXPLORER')} variant='warning' size='sm' className='text-white me-2' disabled={archivedChatDataDownloadLoading} onClick={handleBackupExplorer}>
							{currentBackupSetting.type == 'google' ? <FaGoogleDrive /> : <FaDropbox />} {t('GENERAL.BACKUPEXPLORER')}
						</Button>}
						<Button title={t('GENERAL.DOWNLOADCHAT')} variant='primary' size='sm' className='me-2' disabled={archivedChatDataDownloadLoading} onClick={downloadChatFile}>
							<HiChatBubbleLeftRight /> {t('GENERAL.DOWNLOADCHAT')}
						</Button>
						<Button title={t('GENERAL.DOWNLOADCHARTGRAPH')} variant='danger' size='sm' className='me-2' disabled={archivedChartGraphDownloadLoading} onClick={downloadChartGraphFile}>
							<FaChartPie /> {t('GENERAL.DOWNLOADCHARTGRAPH')}
						</Button>
						<Button title={t('GENERAL.DOWNLOADCHARTDATA')} variant='success' size='sm' className='me-2' disabled={archivedChartDataDownloadLoading} onClick={downloadChartFile}>
							<FaDownload /> {t('GENERAL.DOWNLOADCHARTDATA')}
						</Button>
						<BackButton />
					</div>
				</Col>
			</Row>
			<hr />
			{!Loading && !isEmpty(archivedPatientFormList) &&
				<Row className='mt-4'>
					<Col>
						<Alert variant='success' className='px-3'>
							<div style={{ fontSize: '13px' }} className='ms-1'>
								<FaUserAlt style={{ marginBottom: '2px', marginRight: '5px' }} /> {archivedPatientFormList?.patient_info?.name},

								<FaPhoneAlt style={{ marginBottom: '3px', marginLeft: '5px', marginRight: '5px' }} /> {archivedPatientFormList?.patient_info?.mobile},

								<FaEdit style={{ marginBottom: '3px', marginLeft: '5px', marginRight: '5px' }} /> {t('ARCHIVEDFORMS.REGISTRATIONNO')} ({archivedPatientFormList?.patient_info?.reg_no})
							</div>
							<div style={{ fontSize: '13px' }}>
								<Table borderless size="sm" className='mb-0'>
									<tbody>
										<tr>
											<td><span className='fw-bold'>{t('ARCHIVEDFORMS.DOA')} : </span> {getFormattedDateTime(archivedPatientFormList?.patient_info?.admitted_at)}</td>
										</tr>
										<tr>
											<td><span className='fw-bold'>{t('ARCHIVEDFORMS.DOD')} : </span> {getFormattedDateTime(archivedPatientFormList?.patient_info?.discharged_at)}</td>
										</tr>
										<tr>
											<td><span className='fw-bold'>{t('ARCHIVEDFORMS.IPNO')} : </span> {archivedPatientFormList?.patient_info?.ip_no}</td>
										</tr>
									</tbody>
								</Table>
							</div>
						</Alert>
					</Col>
				</Row>}
			{Loading &&
				<Row className='d-flex justify-content-center mt-4'>
					<Loader />
				</Row>}
			{!Loading &&
				<Row>
					<Col>
						{!isEmpty(archivedPatientFormList) &&
							Object.entries(archivedPatientFormList?.forms).map(([key, val], i) => {
								return <Card className='mt-3' key={i} border='success'>
									<Card.Header className='bg-success text-light'>
										{t('ARCHIVEDFORMS.FORMSFILLBY')}  <span className='text-capitalize'>{key}</span>
									</Card.Header>
									<Card.Body>
										{!isEmpty(val) &&
											<Row className='mt-4' xs={1} md={2} lg={3}>
												{val.map((form, index) => {
													return <Col key={index}>
														<div className='d-flex flex-wrap align-items-end border rounded-2 shadow-sm bg-white mb-3' style={{ minHeight: '160px' }}>
															<Col xs={12} className='p-3 d-inline-block text-decoration-none'>
																<h6 className='text-center'>{form.form_name}</h6>
															</Col>
															<Col xs={12} className='p-3 d-flex justify-content-center gap-1 bg-light border-top'>
																<Button title={t('GENERAL.DOWNLOADHISTORY')} size='sm' disabled={archivedFormDownloadLoading} onClick={() => downloadHistory(form.id)}>
																	<FaDownload /> {t('GENERAL.DOWNLOADHISTORY')}
																</Button>
																{!isEmpty(form?.backup_link) && <Button title={t('GENERAL.BACKUPEXPLORER')} variant='warning' className='text-white' size='sm' disabled={archivedFormDownloadLoading} onClick={() => openBackupLink(form.backup_link)}>
																	<FaExternalLinkAlt /> {t('GENERAL.BACKUPEXPLORER')}
																</Button>}
																{editArchivedForm(form)}
															</Col>
														</div>
													</Col>;
												})
												}
											</Row>}
									</Card.Body>
								</Card>;
							})}
					</Col>
				</Row>}
			<Modal size='lg' show={!isEmpty(checklistModel)} onHide={() => setChecklistModel({})}>
				<Modal.Header closeButton>
					<Modal.Title>Discharge Checklist</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{dischargeCheckListErrorMessage &&
						<Alert variant='danger'>
							<ul className='mb-0'>
								{dischargeCheckListErrorMessage && dischargeCheckListErrorMessage.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					{!isEmpty(checklistModel?.value) && <DynamicForm
						initialValues={checklistModel.value}
						fields={checklistModel.fields}
						handleCancel={() => setChecklistModel({})}
						handleSubmit={handleSubmitFormData}
						parentformuniqueid={checklistModel.dsId}
						loading={Loading}
					/>
					}
				</Modal.Body>
			</Modal>
		</Container>
	);
};

export default ArchivedForms;
